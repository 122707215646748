<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" placeholder="供应商" value-field="belongTo" display-field="belongToName" clearable @change="handleSellerChange" class="filter-item" style="width: 180px;" auto-select-first-option />
      <quick-select v-model="query.buyerErpId" filterable url="api/distributorErps/list" placeholder="下单账户" value-field="erpId" display-field="name" @change="toQuery" class="filter-item"  style="width: 180px;" auto-select-first-option/>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-input v-model="query.docNum" class="filter-item" :maxlength="20" placeholder="输入发货单号搜索" @keypress.enter.native="toQuery" clearable style="width: 220px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" height="200px">
      <el-table-column prop="code" label="商品编码" min-width="130" />
      <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
      <el-table-column label="商品名称" min-width="240">
        <div style="line-height: 1.5;" slot-scope="scope">
          <div>{{scope.row.goodsName}}</div>
            <div class="fc-g">{{$goodsSpecConvert(scope.row.specName)}}</div>
          </div>
        </el-table-column>
      <el-table-column prop="goodsCount" label="数量" align="right" width="80" />
      <el-table-column prop="goodsPrice" label="单价" align="right" width="80" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
      <el-table-column label="合计" align="right" width="100" :formatter="v=>{ return $price(v.goodsCount*v.goodsPrice)}" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
      <el-table-column prop="dependFormCode" label="采购单号" width="110" />
      
      <el-table-column prop="main.formCode" label="发货通知单号" width="120" />
      <el-table-column label="发货通知日期" width="100" :formatter="r => { return new Date(r.main.createAt).format('yyyy/MM/dd'); }" />
      <el-table-column prop="main.goodsPriceAmount" label="单据金额" width="120" align="right" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>      
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.main.status].type">{{status[scope.row.main.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="main.receiverName" label="收货人" min-width="100" />
      <el-table-column prop="description" label="备注" min-width="110" show-overflow-tooltip/>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout=" prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>


<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import Counter from "vue-count-to";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { Counter },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      
      status: {
        unsend: {
          type: "info",
          label: "待审核"
        },
        sended: {
          type: "success",
          label: "已审核"
        },
        received: {
          type: "success",
          label: "已审核"
        }
      },
      
      order: {
        show: false,
        loading: false,
        current: null
      },
      query:{
        sellerId:null,
        dateRange:null,

      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderSendForm/forBuyer/detail";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.startDate = query.dateRange[0];
        query.endDate = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { status: query.status, formType: 0, sort: "createAt,desc" },
        query
      );
      return true;
    },

    handleSellerChange() {
      this.toQuery();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      this.params.page = 0;
      this.params.size = this.total;
      download("api/orderSendForm/forBuyer/detail/download", this.params)
        .then(result => {
          downloadFile(result, "发货通知明细数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    }
  }
};
</script>